import { useCallback, useContext, useEffect } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";

import { PaymentState } from "@/autoGeneratedGlobalTypes";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { useMarketingHook } from "@/marketing/marketingHook";

import { UtmAnalysisEventEnum, useManageUtmData } from "../useManageUtmData";

import { pollingGetPaymentState, pollingGetPaymentStateVariables, pollingGetPaymentState_getMyProfile_balance, pollingGetPaymentState_getPayment } from "./graphql/__generated__/pollingGetPaymentState";
import { setDefaultCardAfterPolling, setDefaultCardAfterPollingVariables } from "./graphql/__generated__/setDefaultCardAfterPolling";
import { POLLING_GET_PAYMENT_STATE } from "./graphql/POLLING_GET_PAYMENT_STATE";
import { SET_DEFAULT_CARD_AFTER_POLLING } from "./graphql/SET_DEFAULT_CARD_AFTER_POLLING";
import { processRobokassaPaymentLinks } from "./utils";

export const usePaymentPolling = () => {
  const {
    setBalance,
    unfinishedPaymentId,
    setUnfinishedPaymentId,
    setIsFixedPaymentForProduct,
    setCurrentPaymentData,
    isFixedPaymentForProduct,
    email,
    successPaymentCallback,
    failPaymentCallback,
    setSuccessPaymentCallback,
    setFailPaymentCallback,
  } = useContext<UserContextType>(UserContext);
  const { marketingSuccessPaymentHandler } = useMarketingHook();
  const { saveUtmData } = useManageUtmData();
  const [_,
    {
      data: paymentStatusData,
      loading: loadingPaymentStatus,
      error: paymentStatusError,
      startPolling,
      stopPolling,
    },
  ] = useLazyQuery<
    pollingGetPaymentState,
    pollingGetPaymentStateVariables>(POLLING_GET_PAYMENT_STATE, {
      variables: { paymentID: unfinishedPaymentId || 0 },
      fetchPolicy: "no-cache",
    });

  const [
    setDefaultCard,
  ] = useMutation<setDefaultCardAfterPolling, setDefaultCardAfterPollingVariables>(SET_DEFAULT_CARD_AFTER_POLLING, { errorPolicy: "all" });

  useEffect(() => {
    if (unfinishedPaymentId && unfinishedPaymentId !== 0) {
      startPolling(2000);
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [unfinishedPaymentId, startPolling, stopPolling]);

  // set_default_Card
  const setDefaultCardHandler = useCallback((data: pollingGetPaymentState_getPayment) => {
    const { card } = data;
    if (card?.id) {
      setDefaultCard({ variables: { cardID: card.id } });
    }
  }, [setDefaultCard]);

  // update_balance
  const setBalanceHandler = useCallback((clientBalanceData: pollingGetPaymentState_getMyProfile_balance) => {
    setBalance({ amount: clientBalanceData.amount, currency: clientBalanceData.currency });
  }, [setBalance]);

  // send_data_for_marketing
  const sendMarketingSuccessPaymentHandler = useCallback((paymentData: pollingGetPaymentState_getPayment) => {
    const { id, amount, isFirstPayment } = paymentData;
    marketingSuccessPaymentHandler({
      paymentId: id,
      amount: amount.amount,
      currency: amount.currency,
      isFirstPayment,
      isFixedPaymentForProduct,
      email,
    });

    saveUtmData({
      eventType: UtmAnalysisEventEnum.PaymentSucceded,
      paymentID: id,
    });
  }, [email, isFixedPaymentForProduct, marketingSuccessPaymentHandler, saveUtmData]);

  // SUCCESS PAYMENT
  const onSuccessHandler = useCallback((pollingData: pollingGetPaymentState) => {
    const { link: linkToPageAfterPaymentWithRobokassa } = pollingData.getPayment;
    setBalanceHandler(pollingData.getMyProfile.balance);
    setDefaultCardHandler(pollingData.getPayment);
    sendMarketingSuccessPaymentHandler(pollingData.getPayment);

    if (linkToPageAfterPaymentWithRobokassa) { // Ссылка после оплаты через карту Робокассы.
      processRobokassaPaymentLinks({
        link: linkToPageAfterPaymentWithRobokassa,
        dataAboutPayment: pollingData.getPayment,
        statusPayment: PaymentState.Success,
      });
    }

    if (!linkToPageAfterPaymentWithRobokassa && successPaymentCallback) {
      successPaymentCallback(pollingData.getPayment);
    }
    setUnfinishedPaymentId(null);
    setIsFixedPaymentForProduct(false);
    setFailPaymentCallback(() =>
      () => {});
    setSuccessPaymentCallback(() =>
      () => {});
  }, [setBalanceHandler, setDefaultCardHandler, sendMarketingSuccessPaymentHandler,
    setFailPaymentCallback, setIsFixedPaymentForProduct, setSuccessPaymentCallback,
    setUnfinishedPaymentId, successPaymentCallback]);

  // FAIL PAYMENT
  const onFailHandler = useCallback((dataAboutPayment: pollingGetPaymentState) => {
    const { link: linkToPageAfterPaymentWithRobokassa } = dataAboutPayment.getPayment;

    if (linkToPageAfterPaymentWithRobokassa) { // Ссылка после оплаты через карту Робокассы.
      processRobokassaPaymentLinks({
        link: linkToPageAfterPaymentWithRobokassa,
        dataAboutPayment: dataAboutPayment.getPayment,
        statusPayment: PaymentState.Failed,
      });
    }
    if (failPaymentCallback) {
      failPaymentCallback(dataAboutPayment.getPayment);
    }

    setIsFixedPaymentForProduct(false);
    setUnfinishedPaymentId(null);
    setFailPaymentCallback(() =>
      () => {});
    setSuccessPaymentCallback(() =>
      () => {});
  }, [failPaymentCallback, setIsFixedPaymentForProduct,
    setUnfinishedPaymentId, setFailPaymentCallback, setSuccessPaymentCallback]);

  useEffect(() => {
    if (paymentStatusData && !loadingPaymentStatus && !paymentStatusError) {
      const { state } = paymentStatusData.getPayment;

      setCurrentPaymentData(paymentStatusData.getPayment);

      if (state === PaymentState.Success) {
        onSuccessHandler(paymentStatusData);
      }

      if (state === PaymentState.Failed) {
        onFailHandler(paymentStatusData);
      }
    }
  }, [loadingPaymentStatus,
    onFailHandler,
    onSuccessHandler,
    paymentStatusData,
    paymentStatusError,
    setFailPaymentCallback,
    setIsFixedPaymentForProduct,
    setSuccessPaymentCallback,
    setCurrentPaymentData,
    setUnfinishedPaymentId]);

  return {
    setUnfinishedPaymentId,
    pollingLoading: loadingPaymentStatus,
  };
};
