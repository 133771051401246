import React, { useContext, useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import Footer from "@layout/footer";
import Header from "@layout/header";
import SessionController from "@layout/sessionController";
import { LayoutProps } from "@layout/types";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { ACCESS_TOKEN } from "@/account/user";
import { UrlParamKeyEnum } from "@/components/constants";
import { GET_CARROT_HASH } from "@/components/layout/Template/graphql/GET_CARROT_HASH";
import UserContext from "@/contexts/User/UserContext";
import { useManageUtmData } from "@/hooks/useManageUtmData";
import { usePaymentPolling } from "@/hooks/usePaymentPolling";
import { isBrowser } from "@/utils/env";
import { convertToPlainPhone, trimAndLowerCase } from "@/utils/stringUtils";

import AuthorizationModals from "../modals/authorizationModals";
import CommonModals from "../modals/commonModals";
import EmailConfirmationModals from "../modals/emailConfirmationModals";
import { FormTypeEnum } from "../modals/types";

import { GOOGLE_REFERER, YANDEX_REFERER } from "./constants";
import {
  checkAffiliateLink,
  checkAffiliateLinkVariables,
} from "./graphql/__generated__/checkAffiliateLink";
import { getCarrotHash } from "./graphql/__generated__/getCarrotHash";
import { CHECK_AFFILIATE_LINK } from "./graphql/CHECK_AFFILIATE_LINK";
import { removeReferralKeyFromCookies, setReferralKeyInCookies } from "./utils";

const Template = ({ children }: LayoutProps) => {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    setClientReferrer,
    userID,
    email,
    phone,
  } = useContext(UserContext);

  const location = useLocation();
  const { storeUtmData } = useManageUtmData();

  usePaymentPolling();

  const [checkAffiliateLinkHandler, { data: checkAffiliateLinkData }] = useLazyQuery<
    checkAffiliateLink,
    checkAffiliateLinkVariables
  >(CHECK_AFFILIATE_LINK, { fetchPolicy: "no-cache" });

  const [getHashForCarrot, { data: carrotHashData }] = useLazyQuery<getCarrotHash>(
    GET_CARROT_HASH,
    { variables: {}, fetchPolicy: "no-cache" },
  );

  const [isAuthrizedInCarrot, setIsAuthrizedInCarrot] = useState(false);

  const params = new URLSearchParams(location.search);
  const refValue = params.get("ref");

  useEffect(() => {
    if (isUserLoggedIn) {
      getHashForCarrot();
    }
  }, [getHashForCarrot, isUserLoggedIn]);

  useEffect(() => {
    // todo: the presence of a token will no longer affect the display of an alert,
    //  you need to consider possible problems
    document.addEventListener("token_changed", () => {
      setIsUserLoggedIn(!!localStorage.getItem(ACCESS_TOKEN));
      if (localStorage.getItem(ACCESS_TOKEN)) {
        // setIsAuthorizationShown(false);
      }
    });

    // todo: https://3.basecamp.com/5069474/buckets/29854839/todos/7063146663
    // document.addEventListener("token_expired", () => {
    //   setAuthParamToURL(location, FormTypeEnum.Login);
    // });
  }, []);

  useEffect(() => {
    storeUtmData();
  }, [storeUtmData]);

  useEffect(() => {
    if (refValue) {
      checkAffiliateLinkHandler({
        variables: {
          link: refValue,
        },
      });
    }
  }, [checkAffiliateLinkHandler, refValue]);

  useEffect(() => {
    const { referrer } = document;
    if (referrer) {
      if (referrer.includes(GOOGLE_REFERER) || referrer.includes(YANDEX_REFERER)) {
        setClientReferrer(document.referrer);
      }
    }
  }, [setClientReferrer]);

  useEffect(() => {
    if (checkAffiliateLinkData?.checkAffiliateLink === undefined) {
      return;
    }

    if (!checkAffiliateLinkData?.checkAffiliateLink) {
      navigate(`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.ReferralKeyError}`);
      removeReferralKeyFromCookies();
    } else if (!isUserLoggedIn && refValue) {
      setReferralKeyInCookies(refValue);
      navigate(`/?${UrlParamKeyEnum.LoginFormModal}=${FormTypeEnum.Login}`);
    }
  }, [isUserLoggedIn, checkAffiliateLinkData, refValue]);

  useEffect(() => {
    if (carrotHashData && userID && isBrowser()) {
      window.carrotquest?.onReady(() => {
        window.carrotquest.auth(userID.toString(), carrotHashData.getCarrotHash);
        setIsAuthrizedInCarrot(true);
      });
    }
  }, [carrotHashData, userID]);

  useEffect(() => {
    if (isAuthrizedInCarrot && (email || phone) && isBrowser()) {
      if (phone) {
        window.carrotquest.identify({ $phone: convertToPlainPhone(trimAndLowerCase(phone)) });
      }
      if (email) {
        window.carrotquest.identify({ $email: trimAndLowerCase(email) });
      }
    }
  }, [email, isAuthrizedInCarrot, phone]);

  useEffect(() => {
    if (window.flocktory && typeof window.flocktory.reInit === "function") {
      window.flocktory.reInit();
    }
  }, [location.href]);

  return (
    <>
      <div className="main__container">
        <Header />
        <main className="main">
          {children}
          <div id="promocode-element-container" />
        </main>
        {/* todo: devide modals and show some only for authorized users for speed optimisation */}
        <AuthorizationModals />
        <EmailConfirmationModals />
        <CommonModals />
        <SessionController />
        {/* {isBrowser() && !getCookieAccepted() && <CookieNotices />} */}
      </div>
      <Footer />
    </>
  );
};

export default Template;
