import React, { createContext } from "react";

import { UserContextType } from "./types";

const UserContext = createContext<UserContextType>({
  /** Flag that shows if user is logged in */
  isUserLoggedIn: false,
  /** Setter for user is logged in */
  setIsUserLoggedIn: () => {},
  /** ID of a user */
  userID: null,
  /** Setter for ID of a user */
  setUserID: () => {},
  UUID: null,
  setUUID: () => {},
  /** User balance */
  balance: null,
  /** Setter for user balance */
  setBalance: () =>
    () => {},
  /** User free minutes */
  freeMinutesCount: 0,
  /** Setter for user free minutes */
  setFreeMinutesCount: () => {},
  /** ID of unfinished payment to fetch it's info via timer until it's fulfilled */
  unfinishedPaymentId: null,
  /** Setter for ID of unfinished payment */
  setUnfinishedPaymentId: () => {},
  email: "",
  setEmail: () => {},
  isEmailVerified: false,
  setIsEmailVerified: () => {},
  name: "",
  setName: () => {},
  phone: "",
  setPhone: () => {},
  tmpPhone: "",
  setTmpPhone: () => {},
  dateOfBirth: "",
  setDateOfBirth: () => {},
  anonymous: false,
  setAnonymous: () => {},
  gender: null,
  setGender: () => {},
  timezone: "",
  setTimezone: () => {},
  promoUnauthorizedUser: "" || null,
  setPromoUnauthorizedUser: () => {},
  clientReferrer: "",
  setClientReferrer: () => {},

  // payment
  isFixedPaymentForProduct: false,
  setIsFixedPaymentForProduct: () => {},
  currentPaymentData: null,
  setCurrentPaymentData: () => {},
  successPaymentCallback: () => {},
  setSuccessPaymentCallback: (() => {}),
  failPaymentCallback: () => {},
  setFailPaymentCallback: (() => {}),
});

export default UserContext;
