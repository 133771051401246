import React, { useContext } from "react";

import { SessionStatus } from "@/autoGeneratedGlobalTypes";
import { InfoStatusEnum } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";

import CancelledAlert from "../alerts/cancelledAlert";
import ConnectionTroublesAlert from "../alerts/connectionTroublesAlert";
import InProgressAlert from "../alerts/inProgressAlert";
import ReviewEndedAlert from "../alerts/reviewEndedAlert";
import UnavailableAlert from "../alerts/unavailableAlert";
import WaitingAlert from "../alerts/waitingAlert";
import ModalWrapper from "../modalWrapper";

import { AlertHocProps } from "./types";

const AlertHoc = (props: AlertHocProps) => {
  const { alertStatus, client, expert, sessionId, sessionType } = props;
  const { isExpert } = useContext(GlobalContext);
  const receiver = isExpert ? client : expert;

  switch (alertStatus) {
    case SessionStatus.waiting:
      return (
        <ModalWrapper>
          <WaitingAlert
            client={client}
            expert={expert}
            isExpert={isExpert}
            sessionId={sessionId}
            sessionType={sessionType}
          />
        </ModalWrapper>
      );
    case "review_ended":
      return (
        <ModalWrapper>
          <ReviewEndedAlert receiver={receiver} />
        </ModalWrapper>
      );
    case InfoStatusEnum.Unavailable:
      return (
        <ModalWrapper>
          <UnavailableAlert receiver={receiver} />
        </ModalWrapper>
      );
    case "cancelled":
      return (
        <ModalWrapper>
          <CancelledAlert receiver={receiver} />
        </ModalWrapper>
      );
    case "connectionTroubles":
      return (
        <ModalWrapper>
          <ConnectionTroublesAlert receiver={receiver} />
        </ModalWrapper>
      );
    case SessionStatus.in_progress:
      return (
        <ModalWrapper>
          <InProgressAlert receiver={receiver} />
        </ModalWrapper>
      );
    default:
      return null;
  }
};

export default AlertHoc;
