import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { QUESTIONNAIRE_MUTATION } from "@layout/modals/graphql/QUESTIONNAIRE_MUTATION";
import {
  convertDateForBack,
  getErrorByCode,
  setAuthParamToURL,
} from "@layout/modals/utils";
import { navigate } from "gatsby";
import _uniqueId from "lodash/uniqueId";

import { getRedirectUrlSessionStorage, setRedirectUrlSessionStorage } from "@/account/redirect";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { isEmailValid } from "@/components/common/Inputs/emailInput/utils";
import { UrlParamKeyEnum } from "@/components/constants";
import { FormTypeEnum } from "@/components/layout/modals/types";
import UserContext from "@/contexts/User/UserContext";
import { useMarketingHook } from "@/marketing/marketingHook";
import { getErrorCode } from "@/utils/apolloUtils";
import { trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import BirthDate from "@components/common/Inputs/BirthDate";

import type { fillQuestionnaire, fillQuestionnaireVariables } from "../../layout/modals/graphql/__generated__/fillQuestionnaire";

import { QuestionnaireModalProps } from "./types";

import "./styles.scss";

const QuestionnaireModal = (props: QuestionnaireModalProps) => {
  const {
    enableEmail = false,
    enableName = false,
    enableBirthDate = false,
  } = props;
  const {
    email: userEmail,
    setEmail: setUserEmail,
    name: userName,
    setName: setUserName,
    dateOfBirth: userBirthDate,
    setDateOfBirth: setUserBirthDate,
  } = useContext(UserContext);
  const { marketingUserFilledEmailForTheFirstTimeHandler } = useMarketingHook();

  const [inputUniqueId] = useState(_uniqueId());
  const [backendEmailErrorCode, setBackendEmailErrorCode] = useState<number | null>(null);
  const [name, setName] = useState(userName || "");
  const [email, setEmail] = useState(userEmail || "");
  const [birthDate, setBirthDate] = useState(userBirthDate || "");
  const [birthDateErrorText, setBirthDateErrorText] = useState<string|null>(null);
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const [
    fillQuestionnaire,
    {
      data: fillQuestionnaireData,
      loading: fillQuestionnaireLoading,
      error: fillQuestionnaireError,
    }] = useMutation<
    fillQuestionnaire,
    fillQuestionnaireVariables
  >(QUESTIONNAIRE_MUTATION);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsEmailTouched(false);
    setBackendEmailErrorCode(null);
  };

  const handleSubmit = () => {
    if (enableEmail && !isEmailValid(email) || birthDateErrorText) {
      return;
    }

    if (enableEmail) {
      setUserEmail(email);
    }
    if (enableName) {
      setUserName(name);
    }
    if (enableBirthDate) {
      setUserBirthDate(birthDate);
    }
    fillQuestionnaire({
      variables: {
        email: enableEmail ? trimAndLowerCase(email) : "",
        name: enableName ? name : "",
        dateOfBirth: enableBirthDate ? convertDateForBack(birthDate) : "",
      },
    });
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  const setDateCallback = (value: string, errorText: string|null) => {
    setBirthDate(value);
    setBirthDateErrorText(errorText);
  };

  useEffect(() => {
    if (fillQuestionnaireData && !fillQuestionnaireLoading && !fillQuestionnaireError) {
      marketingUserFilledEmailForTheFirstTimeHandler();
      setBackendEmailErrorCode(null);
      const redirectUrl = getRedirectUrlSessionStorage() || "";

      if (redirectUrl) {
        navigate(redirectUrl);
        setRedirectUrlSessionStorage("");
      } else {
        // redirecting to Welcome modal for auth by phone
        setAuthParamToURL(window.location, FormTypeEnum.Welcome);
        setAuthParamToURL(window.location, null);
      }
    }

    if (fillQuestionnaireError) {
      // don't provide email in userContext if something is wrong
      // better check for error code 27, but the backend can't do that yet
      setUserEmail("");
      setBackendEmailErrorCode(getErrorCode(fillQuestionnaireError));
    }
  }, [fillQuestionnaireData, fillQuestionnaireLoading, fillQuestionnaireError,
    setUserEmail]);

  const skipHandler = () => {
    const redirectUrl = getRedirectUrlSessionStorage() || "";

    if (redirectUrl) {
      navigate(redirectUrl);
      setRedirectUrlSessionStorage("");
    } else {
      setAuthParamToURL(location, null, UrlParamKeyEnum.LoginFormModal);
    }
  };

  return (
    <>
      <div className="questionnaire__gift">
        <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size20} />
        1-я консультация в подарок
      </div>
      {enableEmail && (
        <div className="input-group">
          <label className="h4" htmlFor={`email${inputUniqueId}`}>
            E-mail *
          </label>
          {/* todo: reuse EmailInput */}
          <input
            type="text"
            id={`email${inputUniqueId}`}
            value={email}
            autoFocus
            onChange={handleEmailChange}
            onKeyDown={onKeyDownHandler}
            onBlur={() =>
              setIsEmailTouched(true)}
            placeholder="maria@mail.ru"
          />
          {backendEmailErrorCode && (
            <div className="input-group__error">{getErrorByCode(backendEmailErrorCode)}</div>
          )}
          {email && !isEmailValid(email) && isEmailTouched && (
            <div className="input-group__error">Введите e-mail в формате maria@mail.ru</div>
          )}
          <div className="input-group__text">Для отправки чеков и&nbsp;персональных промокодов</div>
        </div>
      )}
      {enableName && (
        <div className="input-group">
          <label className="h4" htmlFor={`name${inputUniqueId}`}>
            Как вас зовут?
          </label>
          <input
            type="text"
            value={name}
            onChange={(event) =>
              setName(event.target.value)}
            onKeyDown={onKeyDownHandler}
            id={`name${inputUniqueId}`}
          />
        </div>
      )}
      {enableBirthDate && (
        <div className="input-group">
          <label className="h4" htmlFor={`date${inputUniqueId}`}>
            Дата рождения
          </label>
          <BirthDate
            id={`date${inputUniqueId}`}
            initialValue={birthDate}
            setDateCallback={setDateCallback}
            enterPressCallback={handleSubmit}
            unnecessaryTextOn
          />
          <div className="input-group__error">{birthDateErrorText}</div>
          <div className="input-group__text">
            Поможет эксперту сделать точный расклад. И&nbsp;в&nbsp;день рождения мы&nbsp;пришлём подарок
          </div>
        </div>
      )}
      {!!enableEmail && (
        <Button
          text="Создать аккаунт"
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.Dark}
          onClick={handleSubmit}
          disabled={!isEmailValid(email)}
          isLoading={fillQuestionnaireLoading}
        />
      )}
      {!enableEmail && (
        <>
          <Button
            text="Сохранить"
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Dark}
            onClick={handleSubmit}
            isLoading={fillQuestionnaireLoading}
          />
          <Button
            text="Пропустить"
            size={ButtonSizeEnum.Middle}
            color={ButtonColorEnum.Light}
            onClick={skipHandler}
            isLoading={fillQuestionnaireLoading}
            className="questionnaire__skip-button"
          />
        </>
      )}
    </>
  );
};

export default QuestionnaireModal;
