import { gql } from "@apollo/client";

export const GET_MY_PROFILE_FOR_HEADER = gql`
  query getMyProfileForHeader {
    getMyProfile {
      user {
        id
        uuid
        mobileImage: image(size: 36) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 36) {
          onex
          twox
          threex
        }
        name
        freeMinutesLeft
      }
      email
      balance {
        amount
        currency
      }
      isExpert
      phone
    }
  }
`;
