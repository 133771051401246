import React, { useContext, useMemo, useState } from "react";

import FooterColumn from "@layout/footer/Column";
import FooterMenu from "@layout/footer/Menu";
import { Helmet } from "react-helmet";

import LogoBlock from "@/components/common/logoBlock";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { isBrowser } from "@/utils/env";
import { Icon } from "@components/common/icon";
import Support from "@components/common/support";

import { FOOTER_DATA } from "./constants";
import PaymentInfo from "./PaymentInfo";
import "./styles.scss";

const {
  menus: { knowledge, paymentInfo, aboutUs, benefits },
  beExpert,
  socialLinks,
  bottomData,
} = FOOTER_DATA;

const Footer = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);

  const pathName = isBrowser() ? window?.location?.pathname : "";
  const [isFullTextShown, setIsFullTextShown] = useState(false);

  const showMoreClickHandler = () =>
    setIsFullTextShown((oldValue) =>
      !oldValue);


  const convertedBenefits = useMemo(() =>
    ({
      title: benefits.title,
      items: benefits.items
        .map((item) => {
          if (isExpert && item.link === "/promo") {
            return null; // Не возвращаем  объект, если isExpert и item.link === "/promo"
          }

          return {
            title: item.title,
            link: item.link === "/promo" && isUserLoggedIn ? "/profile/promo-codes" : item.link,
          };
        })
        .filter((item): item is { title: string; link: string } =>
          item !== null), // // Отфильтруем  нулевые значения
    }), [isUserLoggedIn, isExpert]);

  return (
    <>
      <footer className="footer">
        <div className="page-width">
          <div className="footer__inner">
            <div className="footer__left">
              <div className="footer__column footer__logo">
                <LogoBlock className="footer__logo-link" />
                <div className="footer__label">{FOOTER_DATA.logoSubtitle}</div>
              </div>
              <div className="footer__column footer__social">
                {socialLinks.map((social) =>
                  (
                    <a
                      className={`footer__social-item footer__social-item--${social.title.toLowerCase()}`}
                      title={social.title}
                      href={social.link}
                      key={social.title}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon type={social.title} />
                    </a>
                  ))}
              </div>
              <FooterColumn className="footer__support">
                <Support />
              </FooterColumn>
            </div>
            <div className="footer__right">
              <div className="footer__right-column">
                <FooterColumn>
                  <>
                    <h4 className="h4">{aboutUs.title}</h4>
                    <FooterMenu itemsList={aboutUs.items} />
                    <h4 className="h4">{convertedBenefits.title}</h4>
                    <FooterMenu itemsList={convertedBenefits.items} />
                    <h4 className="h4">{paymentInfo.title}</h4>
                    <PaymentInfo />
                  </>
                </FooterColumn>
              </div>
              <div className="footer__right-column">
                <FooterColumn title={knowledge.title}>
                  <FooterMenu itemsList={knowledge.items} />
                </FooterColumn>
                <FooterColumn title={beExpert.title}>
                  <>
                    <a title={beExpert.anchor.title} href={beExpert.anchor.link}>
                      {beExpert.anchor.title}
                    </a>
                    <div className="footer__label">{beExpert.description}</div>
                    <div className="footer__details">
                      <a href={beExpert.details.link}>
                        {beExpert.details.title}
                      </a>
                    </div>
                  </>
                </FooterColumn>
              </div>
            </div>
            <div
              className={`footer__bottom ${
                pathName?.includes("/expert/") ? "footer__margin-bottom" : ""
              }`}
            >
              <noindex>
                <div className="footer__policies">
                  <p>
                    {`Сервис Lunaro.ru (18+) может использоваться в\u00a0информационно-развлекательных целях. Используя Сервис Lunaro.ru, вы принимаете`}
                    {" "}
                    <a
                      title={bottomData.agreement.title}
                      href={bottomData.agreement.link}
                      rel="noreferrer"
                    >
                      {bottomData.agreement.name}
                    </a>
                    {", "}
                    <a
                      title={bottomData.confidence.title}
                      href={bottomData.confidence.link}
                      rel="noreferrer"
                    >
                      {bottomData.confidence.name}
                    </a>
                    {", "}
                    <a
                      title={bottomData.referral.title}
                      href={bottomData.referral.link}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {bottomData.referral.name}
                    </a>
                    {", "}
                    <a
                      title={bottomData.cookie.title}
                      href={bottomData.cookie.link}
                      rel="noreferrer"
                    >
                      {bottomData.cookie.name}
                    </a>
                    {" "}
                    и&nbsp;даёте согласие на
                    {" "}
                    <a
                      title={bottomData.mailings.title}
                      href={bottomData.mailings.link}
                      rel="noreferrer"
                    >
                      {bottomData.mailings.name}
                    </a>
                    .
                  </p>
                  <p>{`Эксперты Сервиса Lunaro.ru не\u00a0являются членами команды Сервиса или его\u00a0представителями. Lunaro.ru тщательно проверяет всех Экспертов и\u00a0даёт допуск к\u00a0работе через Сервис, однако не\u00a0несёт ответственности за\u00a0обещания и\u00a0утверждения, указанные на\u00a0страницах Экспертов и в\u00a0отзывах других Пользователей об\u00a0Экспертах Сервиса.`}</p>
                  <p
                    className={isFullTextShown ? "" : "mobile-hidden"}
                  >
                    {`Lunaro.ru не\u00a0гарантирует, что информация, полученная на\u00a0консультации, приведёт к\u00a0ожидаемым цели и\u00a0результату.`}
                  </p>
                  <p
                    className={isFullTextShown ? "" : "mobile-hidden"}
                  >
                    {`Lunaro.ru не\u00a0гарантирует точное установление дат событий, а\u00a0также местонахождения и\u00a0состояния людей, животных, вещей.`}
                  </p>
                  <p
                    className={isFullTextShown ? "" : "mobile-hidden"}
                  >
                    {`Информационно-развлекательные услуги Lunaro.ru не\u00a0являются заменой или\u00a0подменой квалифицированной медицинской помощи. Эксперты Сервиса Lunaro.ru не\u00a0вправе ставить диагнозы, назначать медицинские препараты и\u00a0оказывать медицинскую помощь — для этого нужно обратиться в\u00a0соответствующее медицинское учреждение.`}
                  </p>
                  <p
                    className={isFullTextShown ? "" : "mobile-hidden"}
                  >
                    {`\u00a0ООО\u00a0«Футура», г.\u00a0Санкт-Петербург, 8-я линия В.О., д.\u00a025, к.\u00a0А, пом.\u00a015Н, оф.\u00a014-1. ИНН:\u00a07801716423 КПП:\u00a0780101001 ОГРН:\u00a01227800119007`}
                  </p>
                  <div
                    className="footer__show-more"
                    onClick={showMoreClickHandler}
                    onKeyPress={showMoreClickHandler}
                    role="button"
                    tabIndex={0}
                  >
                    {isFullTextShown ? "Скрыть" : "Показать ещё"}
                  </div>
                </div>
                <div className="footer__copyright">{bottomData.copyright}</div>
              </noindex>
            </div>
          </div>
        </div>
      </footer>
      <Helmet>
        {/* Carrot quest BEGIN */}
        <script type="text/javascript">
          {`!function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("53085-2c065d496d742578110c022882");`}
        </script>
        {/* Carrot quest END */}
      </Helmet>
    </>
  );
};

export default Footer;
