import { navigate } from "gatsby";

import { PaymentState } from "@/autoGeneratedGlobalTypes";
import { getSuccessUrl } from "@/components/profile/paymentForm/utils";

import { pollingGetPaymentState_getPayment } from "./graphql/__generated__/pollingGetPaymentState";
import { ROBOKASSA_PAYMENT_FROM_PROFILE_PAGE } from "./types";

type ProcessRobokassaPaymentLinksType= {
  link: string,
  statusPayment: PaymentState,
  dataAboutPayment: pollingGetPaymentState_getPayment,
}

export const processRobokassaPaymentLinks = (
  { link, statusPayment, dataAboutPayment }: ProcessRobokassaPaymentLinksType,
) => {
  const url = new URL(link);
  const pathAfterDomain = url.href.replace(`${url.origin}/`, "");
  const successUrlForRediract = getSuccessUrl(pathAfterDomain);

  switch (true) {
    case pathAfterDomain.includes(ROBOKASSA_PAYMENT_FROM_PROFILE_PAGE): {
      if (statusPayment === PaymentState.Success) {
        navigate(successUrlForRediract || `/profile/balance`, { state: { showPaymentAnimation: true, resultData: dataAboutPayment } });
        break;
      }
      navigate(ROBOKASSA_PAYMENT_FROM_PROFILE_PAGE);
      break;
    }
    default:
      navigate(`/${pathAfterDomain}?request=${dataAboutPayment.state.toLowerCase()}`, { replace: true });
      break;
  }
};
