import React, { useContext, useEffect } from "react";

import { navigate } from "gatsby";

import { Button, ButtonIconPositionEnum } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";
import UserInfo from "@/components/common/userInfo";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import { useMarketingHook } from "@/marketing/marketingHook";

import { UnavailableAlertProps } from "./types";

import "../../styles.scss";
import "./styles.scss";

const UnavailableAlert = ({ receiver }: UnavailableAlertProps) => {
  const { setIsAlertShown, setAlertStatus } = useContext(GlobalContext);
  const { reachCustomYandexMetricsGoal } = useMarketingHook();

  const clearAlert = () => {
    setAlertStatus(null);
    setIsAlertShown(false);

    navigate(`/${CATALOG_PAGE_NAME}`);
  };

  useEffect(() => {
    reachCustomYandexMetricsGoal("alert-expert-unavailable");
  }, [reachCustomYandexMetricsGoal]);

  return (
    <>
      <div className="modal__top">
        <Icon
          className="modal__close"
          onClick={clearAlert}
          type={IconTypeEnum.Close}
          size={IconSizeEnum.Size32}
        />
      </div>
      <div
        className="modal__content alert__modal alert-unavailable"
      >
        {receiver && (
          <UserInfo
            avatar={(
              <UserAvatar
                mobileImage={receiver.mobileImage}
                desktopImage={receiver.desktopImage}
                name={receiver.name}
                userId={receiver.id}
                type={UserAvatarTypeEnum.Call}
              />
            )}
          >
            <div className="alert__modal-icon alert-unavailable__modal-icon">
              <Icon type={IconTypeEnum.CallIncoming} size={IconSizeEnum.Size48} />
            </div>
          </UserInfo>
        )}
        <div className="alert__modal-icon alert-unavailable__modal-icon">
          <Icon type={IconTypeEnum.Exclamation} size={IconSizeEnum.Size48} />
        </div>
        <h2 className="h2">Экcперт сейчас недоступен</h2>
        <p>Вероятно, его рабочий день окончен, или он проводит консультацию кому-то</p>
        <div className="alert__modal-buttons">
          <div className="alert__modal-button">
            <Button
              text="Обновить страницу"
              icon={IconTypeEnum.Reload}
              iconPosition={ButtonIconPositionEnum.Left}
              onClick={clearAlert}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnavailableAlert;
