import React from "react";

import logo from "@images/logo_slogan.svg";

import OnboardingBanner from "@/components/layout/header/OnboardingBanner";

import { Icon, IconSizeEnum, IconTypeEnum } from "../../../common/icon";

import { ModalWithStepProps } from "./types";

import "./styles.scss";

const ModalWithStep = ({
  children,
  prevStep,
  closeModalHandler,
  step,
}: ModalWithStepProps) => {
  const isShow = true;
  return (
    <div
      className={`modal-step2__container${isShow ? " is-opened" : ""} ${`modal-step2--${step}-container`}`}
      onClick={closeModalHandler}
    >
      <div className={`modal-step2 ${`modal-step2--${step}`}`}>
        <div className="modal-step2__inner">
          <div
            className="modal-step2__form"
            onClick={(e) =>
              e.stopPropagation()}
          >
            {step !== 3 && <OnboardingBanner />}
            <div className="modal-step2__top">
              {step < 4
                ? <img itemProp="image" src={logo} alt="Логотип Lunaro" />
                : (
                  <div className="modal-step2__icon-block">
                    <div className="modal-step2__time-icon-background" />
                    <Icon className="modal-step2__time-icon" type={IconTypeEnum.TimeGradient} size={IconSizeEnum.Size40} viewBox="40" />
                  </div>
                )}
              {step !== 3 && (
                <Icon
                  className="modal-step2__close"
                  onClick={closeModalHandler}
                  type={IconTypeEnum.Close}
                  size={IconSizeEnum.Size32}
                />
              )}

              {step <= 2 && step !== 1 && (
                <Icon
                  className="modal-step2__back"
                  onClick={prevStep}
                  type={IconTypeEnum.Left}
                  size={IconSizeEnum.Size32}
                />
              )}
            </div>
            <div className="modal-step2__content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalWithStep;
