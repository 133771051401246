import { gql } from "@apollo/client";

export const POLLING_GET_PAYMENT_STATE = gql`
  query pollingGetPaymentState($paymentID: Int!) {
    getPayment(paymentID: $paymentID) {
      id
      amount{
        amount
        currency
      }
      state
      isFirstPayment
      resultCode
      errorValue
      datetime
      card {
        id
        number
        type
      }
      link
    }
    getMyProfile {
      balance {
        amount
        currency
      }
    }
  }
`;
